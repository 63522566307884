<template>
    <div style="text-align: center;margin-top: 10px;" class="cursor-pointer" @click="switch_mode()">
        <img v-if="mode == 'dark'" src="@/assets/images/icon/light-mode.svg" alt="" >
        <img v-else src="@/assets/images/icon/dark-mode.svg" alt="" >
    </div>   
       
        
         
    <div class="" v-if="!isLogin()">
        <div>
            <div v-if="success" class="text-center" style="margin-top: 14%">
                <div class="regis_success">
                    <font-awesome-icon icon="check" :style="{ fontSize: '48px', color: '#fff' }" />                    
                </div>
                <div style="font-weight: 600;font-size:1rem;" v-html="message"></div>
            </div>
            <form v-if="!success" @submit.prevent="submit()" class="form-signin form-register main-overlay">
                <div class="mb-4">
                    <router-link to="/">
                        <img v-if="mode == 'dark'" src="@/assets/images/logo_dark.svg" style="height: 60px; margin-bottom: -5px;" alt="" >
                        <img v-else src="@/assets/images/logo.svg" style="height: 60px; margin-bottom: -5px;" alt="" >  
                    </router-link>  
                </div>
                <div class="row">
                    
                    <div class="col-12">
                        <div class="form-input mb-3">
                            <p class="title-top-input">Name<span class="text-red">*</span></p>                                        
                            <input type="text" class="form-control form-cs" required="true" placeholder="Write here" v-model="form_data.name" >
                        </div>
                    </div>
                    <div class="col-12">
                        <div class="form-input mb-3">
                            <p class="title-top-input">Surname<span class="text-red">*</span></p>                                        
                            <input type="text" class="form-control form-cs" required="true" placeholder="Write here" v-model="form_data.lastname" >
                        </div>
                    </div>
                    <div class="col-12">
                        <div class="form-input mb-3">
                            <p class="title-top-input">Company</p>                                        
                            <input type="text" class="form-control form-cs" placeholder="Write here" v-model="form_data.company" >
                        </div>
                    </div>
                    <div class="col-12">
                        <div class="form-input mb-3">
                            <p class="title-top-input">Job Title<span class="text-red">*</span></p>                                        
                            <input type="text" class="form-control form-cs" required="true" placeholder="Write here" v-model="form_data.jobtitle" >
                        </div>
                    </div>
                    <div class="col-12">
                        <div class="form-input mb-3">
                            <p class="title-top-input">Work email<span class="text-red">*</span></p>                                        
                            <input type="email" class="form-control form-cs" required="true" placeholder="Write here" v-model="form_data.email" @change="checkemail">
                        </div>
                    </div>
                    <div class="col-12" v-if="network_id_show">
                        <div class="form-input mb-2">
                            <p class="title-top-input">Network ID<span class="text-red">*</span></p>                                        
                            <input type="text" class="form-control form-cs" required="true" placeholder="Write here" v-model="form_data.network_id">
                        </div>
                    </div>
                    <div class="col-12">
                        <div class="form-input mb-3">
                            <p class="title-top-input">Country<span class="text-red">*</span></p> 
                            <select class="form-cs" required="true" :disabled="list_country.length == 0" v-model="form_data.mailing_country" >
                                <option value="" >--- select  ---</option>
                                <option v-for="(item, key) in list_country" :key="key" :value="item.country_name">{{item.country_name}}</option>
                            </select>                                       
                        </div>
                    </div>
                    <div class="col-12" v-if="location_show">
                        <div class="form-input mb-3">
                            <p class="title-top-input">Location<span class="text-red">*</span></p> 
                            <select class="form-cs" required="true" v-model="form_data.location_novartis" >
                                <option value="" >--- select location ---</option>
                                <option v-for="(item, key) in list_location" :key="key" :value="item">{{item}}</option>
                            </select>                                       
                        </div>
                        <div class="form-input mb-3">
                            <p class="title-top-input">Business unit<span class="text-red">*</span></p> 
                            <select class="form-cs" required="true" v-model="form_data.novartis_department" >
                                <option value="" >--- select business unit ---</option>
                                <option v-for="(item, key) in list_department" :key="key" :value="item">{{item}}</option>
                            </select>                                       
                        </div>
                    </div>
                    <div class="col-12">
                        <div class="form-input mb-3">
                            <p class="title-top-input">Invitation code</p>                                        
                            <input type="text" class="form-control form-cs" placeholder="Write here" v-model="form_data.invitation_code" >
                        </div>
                    </div>
                </div>
                <div class="main-input mt-3 mb-4">
                    <div class="box-check" style="background:none;padding:0;">
                        <label>
                            <input type="checkbox" class="form-check-input" v-model="accept" :required="true" name="accept">
                            <span class="check-recherche"></span>
                            <div class="txt-check font-oxygen-bold">
                                I accept the <a target="_blank" rel="noopener" style="text-decoration: underline!important;color:#0d6efd" href="https://www.prioritis.com/prioritis-terms-and-conditions">terms and conditions</a> 
                                and <a target="_blank" rel="noopener" style="text-decoration: underline !important;color:#0d6efd" href="https://www.prioritis.com/Privacy-Policy">privacy policy.</a> <span style="color:red">*</span>
                            </div>
                        </label>
                    </div>
                </div>
                <div class="button-search">
                    <button class="btn btn-sea-blue btn-sm w-50" :class="!accept ? 'btn-disabled' : ''" type="submit" :disabled="!accept">SUBMIT</button>
                </div>
                <div v-if="loading" class="overlay">
                    <div class="loadding_circle"></div>
                </div>
            </form>
        </div>
    </div>
     
    <div class="modal fade" ref="confirmlogout" id="modal_error" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered modal-dialog-cs">
            <div class="modal-content">
                <div class="confirm-feedback">
                    <div class="regis_success" style="background: #f51717;width: 50px">
                        <font-awesome-icon icon="times" :style="{ fontSize: '28px', color:'#fff'}" />                        
                    </div>
                    <span v-html="message"></span>
                    <div class="box-area">
                        <div class="box-btn-menu">
                            <button class="btn btn-sea-blue btn-sm" style="margin: auto;" data-bs-dismiss="modal" >  OK </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import { computed, reactive, ref, onMounted, onUnmounted, watch } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router';
import useModeApp  from "@/hooks/useModeApp.js"
import { Modal } from 'bootstrap'
import { isLogin } from "@/utils";

export default {
    components: {
    },
    setup(){
        const store = useStore()
        const router = useRouter() 

        if (isLogin()) {
            router.push("/");
        }

        //-------------------------- switch mode  ------------------------[start]  
        const { mode, switch_mode, addClassToBody } = useModeApp();      
        watch(mode, ()  => { 
            addClassToBody() 
        })
        onMounted(() => {
            addClassToBody()
        });
        onUnmounted(() => {
            document.body.classList.remove('light-mode', 'dark-mode')
        })
        //-------------------------- switch mode  ------------------------[end]


        const form_data = reactive({
                name : "",
                lastname : "",
                mailing_country : "",
                jobtitle : "",
                email : "",
                company : "",
                location_novartis : "",
                invitation_code : "",
                novartis_department : "",
                network_id: ""
        });
        const accept = ref(false);
        store.dispatch('users/get_list_country')
        const list_country = computed(() => store.state.users.list_country); 
        store.dispatch("auth/get_domain_names_internal")
        const internal_user_domains = computed(() => store.state.auth.internal_user_domains);
        
        const chcekInternalUser = (text) => {
            const email = text.split('@')[1];
            let is_internal_user = false;
            internal_user_domains.value.forEach(element => {
                if(email.includes(element.trim())){
                    is_internal_user = true;
                }
            });
            return is_internal_user;
        }

        /**
         * On click SUBMIT 
         */
        const loading = ref(false);
        const success = ref(false);
        const message = ref("");
        const submit = () => {
            console.log(form_data)
            loading.value = true
            store.dispatch("auth/register", form_data ).then(
                (res) => {
                    console.log(res)
                    message.value = res.message;
                    success.value = true
                    loading.value = false
                },
                (error) => {
                    if(error.response && error.response.data && error.response.data.message){
                        message.value = error.response.data.message;
                    }else{
                        message.value = error
                    }
                    loading.value = false
                    modal.value.show();                              
                }
            );
        }



        const location_show = ref(false)
        const network_id_show = ref(false)
        const list_location = ["Global", "CONEXTS", "Region Europe", "Region APMA", "Region AMACO", "Region LACan", "CPO"];
        const list_department = ["Oncology", "Pharma"];
        const checkemail = () => {
            console.log(form_data.email)   
            const text = form_data.email.toLowerCase();       
            if (text.search("@novartis.com") !== -1) {  
                location_show.value = true;
                network_id_show.value = false;
                form_data.network_id = "";
            }else if (chcekInternalUser(text)) {  
                network_id_show.value = true;
                form_data.location_novartis = "";
                form_data.novartis_department = "";
                location_show.value = false;
            }else{
                network_id_show.value = false;
                form_data.location_novartis = "";
                form_data.novartis_department = "";
                form_data.network_id = "";
                location_show.value = false;
            }
        }

        const modal = ref(null);
        onMounted(() => {
            modal.value = new Modal(document.getElementById('modal_error'));             
        })

        return {form_data, accept, list_country, submit, location_show, list_department, network_id_show,
        list_location, loading, success, message, checkemail, switch_mode, mode, isLogin}
    }
}

</script>
